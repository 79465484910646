import fusionvideo1 from '../../../../assets/projects/Fusion/fusion global.jpg'
import fusionvideo2 from '../../../../assets/projects/Fusion/fusion papular.jpg'
import fusionvideo3 from '../../../../assets/projects/Fusion/fusion.jpg'
import steamvideo1 from '../../../../assets/projects/Steam Flow Meter/steam.jpg'
import steamvideo2 from '../../../../assets/projects/Steam Flow Meter/steam1.jpg'
// import burner from '../../../../assets/projects/burner.jpg'
// import flowmeter from '../../../../assets/projects/flow meter.jpg'
// import intro from '../../../../assets/projects/intro.jpg'
// import water from '../../../../assets/projects/water.jpg'
import air1 from '../../../../assets/projects/Air Flow Meter/5.jpg'
import air2 from '../../../../assets/projects/Air Flow Meter/14.jpg'
import air3 from '../../../../assets/projects/Air Flow Meter/17.jpg'
import air4 from '../../../../assets/projects/Air Flow Meter/20.jpg'

import gas1 from '../../../../assets/projects/Gass Flow Meter/1.jpg'
import gas2 from '../../../../assets/projects/Gass Flow Meter/2.jpg'
import gas3 from '../../../../assets/projects/Gass Flow Meter/3.jpg'
import gas4 from '../../../../assets/projects/Gass Flow Meter/4.jpg'

import condensate1 from '../../../../assets/projects/Condensate Metering Pump/1.jpg'
import condensate2 from '../../../../assets/projects/Condensate Metering Pump/1_1.jpg'

import emission1 from '../../../../assets/projects/Oxygen Analyser/2.jpg'
import emission2 from '../../../../assets/projects/Oxygen Analyser/3.jpg'
import emission3 from '../../../../assets/projects/Oxygen Analyser/10.jpg'
import emission4 from '../../../../assets/projects/Oxygen Analyser/11.jpg'

export const fusioncompany = [
  {
    img: fusionvideo1,
    companyname: "Fusion Global Export",
    link: "https://www.youtube.com/watch?v=XR-0-0YUj7Y",
  },
  {
    img: fusionvideo2,
    companyname: "Fusion System in Popular Fabrics",
    link: "https://www.youtube.com/shorts/gcIDxT8EP_M",
  },
  {
    img: fusionvideo3,
    companyname: "Fusion Micro Modulation Combustion Control with O2 Trim",
    link: "https://www.youtube.com/watch?v=TPfus5FnZvA",
  },
];

export const aircompany = [
  {
    img: air1,
    companyname: "Air Flow Meter in Emporium Mall",
    link: "https://www.youtube.com/channel/UC4e-X0cdFQhA5GuuqhqRwqA",
  },
  {
    img: air2,
    companyname: "Air Flow Meter",
    link: "https://www.youtube.com/channel/UC4e-X0cdFQhA5GuuqhqRwqA",
  },
  {
    img: air3,
    companyname: "Air Flow Meter",
    link: "https://www.youtube.com/channel/UC4e-X0cdFQhA5GuuqhqRwqA",
  },
  {
    img: air4,
    companyname: "Air Flow Meter",
    link: "https://www.youtube.com/channel/UC4e-X0cdFQhA5GuuqhqRwqA",
  },
];

export const gascompany = [
  {
    img: gas1,
    companyname: "Gas Flow Meter",
    link: "https://www.youtube.com/channel/UC4e-X0cdFQhA5GuuqhqRwqA",
  },
  {
    img: gas2,
    companyname: "Gas Flow Meter",
    link: "https://www.youtube.com/channel/UC4e-X0cdFQhA5GuuqhqRwqA",
  },
  {
    img: gas3,
    companyname: "Gas Flow Meter",
    link: "https://www.youtube.com/channel/UC4e-X0cdFQhA5GuuqhqRwqA",
  },
  {
    img: gas4,
    companyname: "Gas Flow Meter",
    link: "https://www.youtube.com/channel/UC4e-X0cdFQhA5GuuqhqRwqA",
  },
];

export const steamcompany = [
  {
    img: steamvideo1,
    companyname: "Steam Flow Metering on WHRB at Lucky Textile unit",
    link: "https://www.youtube.com/watch?v=f0mVFBPEaiw",
  },
  {
    img: steamvideo2,
    companyname: "Steam Flow Metering at lucky textile",
    link: "https://www.youtube.com/watch?v=hsUmLf2PdeA",
  },
];

export const emissioncompany = [
  {
    img: emission1,
    companyname: "Emission Monitoring System",
    link: "https://www.youtube.com/channel/UC4e-X0cdFQhA5GuuqhqRwqA",
  },
  {
    img: emission2,
    companyname: "Emission Monitoring System",
    link: "https://www.youtube.com/channel/UC4e-X0cdFQhA5GuuqhqRwqA",
  },
  {
    img: emission3,
    companyname: "Emission Monitoring System",
    link: "https://www.youtube.com/channel/UC4e-X0cdFQhA5GuuqhqRwqA",
  },
  {
    img: emission4,
    companyname: "Emission Monitoring System",
    link: "https://www.youtube.com/channel/UC4e-X0cdFQhA5GuuqhqRwqA",
  },
];

export const condensatecompany = [
  {
    img: condensate1,
    companyname: "Condensate Monitoring Pump",
    link: "https://www.youtube.com/channel/UC4e-X0cdFQhA5GuuqhqRwqA",
  },
  {
    img: condensate2,
    companyname: "Condensate Monitoring Pump",
    link: "https://www.youtube.com/channel/UC4e-X0cdFQhA5GuuqhqRwqA",
  },
];