import FS1 from  '../../../../../assets/products/FS1.png'
import OA2 from  '../../../../../assets/products/OA2.png'
import SFM3 from '../../../../../assets/products/SFM3.png'
import TMFM4 from '../../../../../assets/products/TMFM4.png'
import FLS5 from '../../../../../assets/products/FLS5.png'
import CMP6 from '../../../../../assets/products/CMP6.png'
import WQA7 from '../../../../../assets/products/WQA7.png'
import EM8 from '../../../../../assets/products/EM8.png'
import IRT9 from '../../../../../assets/products/IRT9.png'
import THDT10 from '../../../../../assets/products/THDT10.png'
import MTCMS11 from '../../../../../assets/products/MTCMS11.png'
import EMS12 from '../../../../../assets/products/EMS12.png'
import RDT13 from '../../../../../assets/products/RDT13.png'
import ES14 from '../../../../../assets/products/ES14.png'
import GENSET15 from '../../../../../assets/products/GENSET15.png'
import GSM16 from '../../../../../assets/products/GSM16.png'


import fusionfile       from     '../../../../../assets/pdf/Fusion System.pdf'
import oxygenfile       from     '../../../../../assets/pdf/Oxygen Analyzer.pdf'
import steamfile        from     '../../../../../assets/pdf/Steam Flow Meter.pdf'
import airgasfile       from     '../../../../../assets/pdf/Air & Gas Flow Meter.pdf'
import fuelfile         from     '../../../../../assets/pdf/Fuel Level Sensor.pdf'
import condensatefile   from     '../../../../../assets/pdf/Condensate Monitoring Pump.pdf'
import waterfile        from     '../../../../../assets/pdf/Water Quality Analyzer.pdf'
import energyfile       from     '../../../../../assets/pdf/Energy Meter.pdf'
import tempsensorfile   from     '../../../../../assets/pdf/IR Temperature Sensor Data Terminal.pdf'
import temphumidityfile from     '../../../../../assets/pdf/Temperature & Humidity Data Terminal.pdf'
import milkfile         from     '../../../../../assets/pdf/Milk Tank Control & Monitoring System.pdf'
import emissionfile     from     '../../../../../assets/pdf/Emission Monitoring System.pdf'
import resistorfile      from     '../../../../../assets/pdf/Resistor Depending Temperature Sensor.pdf'
import environmentalfile from     '../../../../../assets/pdf/Environmental Sensor.pdf'
import gensetfile        from     '../../../../../assets/pdf/GenSet Health Monitoring System.pdf'
import gsmfile        from     '../../../../../assets/pdf/GSM Data Logger.pdf'


export const fusion = [
  {
    img: FS1,
    companyname: "DOWNLOAD",
    download: fusionfile,
  },
];

export const oxygen = [
  {
    img: OA2,
    companyname: "DOWNLOAD",
    download: oxygenfile,
  },
];

export const steam = [
  {
    img: SFM3,
    companyname: "DOWNLOAD",
    download: steamfile,
  },
];

export const airgas = [
  {
    img: TMFM4,
    companyname: "DOWNLOAD",
    download: airgasfile,
  },
];

export const fuel = [
  {
    img: FLS5,
    companyname: "DOWNLOAD",
    download: fuelfile,
  },
];

export const condensate = [
  {
    img: CMP6,
    companyname: "DOWNLOAD",
    download: condensatefile,
  },
];

export const water = [
  {
     img: WQA7,
     companyname: "DOWNLOAD",
     download: waterfile,
   },
];

export const energy = [
   {
    img: EM8,
    companyname: "DOWNLOAD",
    download: energyfile,
   },
];

export const tempsensor = [
  {
    img: IRT9,
    companyname: "DOWNLOAD",
    download: tempsensorfile,
  },
];

export const temphumidity = [
  {
    img: THDT10,
    companyname: "DOWNLOAD",
    download: temphumidityfile,
  },
];

 export const milk = [
   {
     img: MTCMS11,
     companyname: "DOWNLOAD",
     download: milkfile,
   },
];

export const emission = [
   {
     img: EMS12,
     companyname: "DOWNLOAD",
     download: emissionfile,
   },
];

export const resistor = [
  {
    img: RDT13,
    companyname: "DOWNLOAD",
    download: resistorfile,
  },
];

 export const environmental = [
   {
     img: ES14,
     companyname: "DOWNLOAD",
     download: environmentalfile,
   },
];

export const genset = [
   {
     img: GENSET15,
     companyname: "DOWNLOAD",
     download: gensetfile,
   },
];

export const gsm = [
  {
    img: GSM16,
    companyname: "DOWNLOAD",
    download: gsmfile,
  },
];

