import React from 'react'
import './Bms.css'
import img1 from '../../assets/background/Bms1.jpg'
import img2 from '../../assets/background/Bms2.jpg'
import img3 from '../../assets/background/Bms3.jpg'
import img4 from '../../assets/background/Bms4.jpg'
import 'react-toastify/dist/ReactToastify.css'

export default function BMS() {
    return (

<div className="container-bms-main">
    <div className='bmsbar'></div>
<div  className="heading-bms-top" >BUILDING MANAGEMENT SYSTEM</div>
        <div className='bms'>
            <img src={img1} alt="Uploading" />
            <img src={img2} alt="Uploading" />
            <img src={img3} alt="Uploading" />
            <img src={img4} alt="Uploading" /> 
        </div>
        </div>
    )
}