import React from 'react'

// import ProjectHead from './ProjectHead';
import img1 from '../../../../assets/background/Project2.jpg'
import CommonImage from '../../../Common/CommonImage/CommonImage';

import ProjectDetail from './ProjectDetail';

export default function ProjectDetailMain() {
  return (
    <>
      {/* <ProjectHead /> */}
      <CommonImage img ={img1} />
      <ProjectDetail />
    </>
  )
}
