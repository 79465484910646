import './Navbar.css';
import logo from '../../assets/logo/logo.png';
import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import DehazeIcon from '@mui/icons-material/Dehaze'; // Adjust the import based on your setup

export default function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const navbarRef = useRef(null);
  const location = useLocation();

  // Function to close the navbar
  const closeNavbar = () => {
    setIsNavExpanded(false);
    scrollToTop(); // Scroll to top when closing the navbar
  };

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'instant' // Smooth scroll
    });
  };

  // Close the navbar and scroll to top when the route changes
  useEffect(() => {
    closeNavbar();
  }, [location]);

  // Handle scroll event to show/hide navbar
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTop) {
        // Scrolling down
        setIsNavbarVisible(false);
      } else {
        // Scrolling up
        setIsNavbarVisible(true);
      }

      // Update last scroll position
      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  // Apply the visibility of the navbar using transform style
  useEffect(() => {
    if (navbarRef.current) {
      navbarRef.current.style.transform = isNavbarVisible ? 'translateY(0)' : 'translateY(-100%)';
      navbarRef.current.style.transition = 'transform 0.3s ease'; // Smooth transition for hiding/showing
    }
  }, [isNavbarVisible]);

  return (
    <>
      <nav id='navbar' className="navigation" ref={navbarRef}>
        <div className="logo">
          <Link to="/home" onClick={scrollToTop}>
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <button
          className="hamburger"
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
            scrollToTop(); // Scroll to top when toggling the navbar
          }}
        >
          <DehazeIcon />
        </button>
        <div
          className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}
        >
          <ul>
            <li className="item">
              <NavLink
                to="/home"
                onClick={closeNavbar}
              >
                Home
              </NavLink>
            </li>
            <li className="item">
              <NavLink
                to="/about"
                className='btn'
                onClick={closeNavbar}
              >
                About Us
              </NavLink>
            </li>
            <li className="item">
              <NavLink
                to="/project"
                className='btn'
                onClick={closeNavbar}
              >
                Projects
              </NavLink>
            </li>
            <li className="item">
              <NavLink
                to="/product"
                className='btn'
                onClick={closeNavbar}
              >
                Products
              </NavLink>
            </li>
            <li className="item">
              <NavLink
                to="/bms"
                className='btn'
                onClick={closeNavbar}
              >
                BMS
              </NavLink>
            </li>
            <li className="item">
              <NavLink
                to="/contact"
                className='btn'
                onClick={closeNavbar}
              >
                Contact Us
              </NavLink>
            </li>
            <li className="item">
              <NavLink
                to="/career"
                className='btn'
                onClick={closeNavbar}
              >
                Careers
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
