import React from 'react'
import './Product.css'

import { useNavigate } from "react-router-dom";

import product1 from  '../../../../assets/products/new product 1.png';
import product2 from  '../../../../assets/products/new product 2.png';
import product3 from  '../../../../assets/products/new product 3.png';
import product4 from  '../../../../assets/products/new product 4.png';
import product5 from  '../../../../assets/products/new product 5.png';
import product6 from  '../../../../assets/products/new product 6.png';
import product7 from  '../../../../assets/products/new product 7.png';
import product8 from  '../../../../assets/products/new product 8.png';
import product9 from  '../../../../assets/products/new product 9.png';
import product10 from '../../../../assets/products/new product 10.png';
import product11 from '../../../../assets/products/new product 11.png';
import product12 from '../../../../assets/products/new product 12.png';
import product13 from '../../../../assets/products/new product 13.png';
import product14 from '../../../../assets/products/new product 14.png';
import product15 from '../../../../assets/products/new product 15.png';
import product16 from '../../../../assets/products/new product 16.png';

import { fusion, oxygen, steam, airgas, fuel, condensate, water, energy, tempsensor, temphumidity, milk, emission, resistor, environmental, genset, gsm } from '../ProductProduct/ProductDetail/ProductsArray';

const productMap = [
    {
        productimg: product1,
        heading: "BMS COMBUSTION CONTROL",
        para: "FUSION is a pre-engineered Automation System for gas/oil fired industrial steam boilers water tube/smoke tube, gas/oil fired chillers, furnaces and duct burners.",
        name: "BMS COMBUSTION CONTROL",
        arr: fusion,
    },
    {
        productimg: product2,
        heading: "OXYGEN ANALYZER",
        para: "The OXY3600 oxygen probe with zirconia sensor and process display unit are the most advanced process system as compare other similar systems available.",
        name: "OXYGEN ANALYZER",
        arr: oxygen,
    }, 
    {
        productimg: product3,
        heading: "STEAM FLOW METER",
        para: "FCU5400 is a Differential Pressure (DP) based flow measurements with temperature and pressure compensation. ",
        name: "STEAM FLOW METER",
        arr: steam,
    },
    {
        productimg: product6,
        heading: "CONDENSATE METERING PUMP",
        para: "OCMP9000 are accurate, reliable, and very cost effective as compare with other renown brands. Latest metering pumps are available from 30 Liters to larger volume up to 120 Liters.",
        name: "CONDENSATE METERING PUMP",
        arr: condensate,
    },
    {
        productimg: product4,
        heading: "AIR/GAS FLOW METER",
        para: "OGF5400 latest version is an intelligent solution for accurate consumption measurement for compressed air and gases.",
        name: "AIR/GAS FLOW METER",
        arr: airgas,
    },
    {
        productimg: product5,
        heading: "FUEL LEVEL SENSOR",
        para: "FLS3600 offers precise consumption and availability of fuel in fuel tank with advanced LIDAR technology as compare with other well-known brands.",
        name: "FUEL LEVEL SENSOR",
        arr: fuel,
    },
    {
        productimg: product7,
        heading: "WATER QUALITY ANALYZER",
        para: "TDS900 is an advanced solution for analyzing water quality. This is accurate, reliable, and very cost effective as compare with other renown brands.",
        name: "WATER QUALITY ANALYZER",
        arr: water,
    },
    {
        productimg: product8,
        heading: "ENERGY METER",
        para: "EM900 is an intelligent solution for accurate consumption & measurement of electric energy consumed by a Industry, or an electrically powered device.",
        name: "ENERGY METER",
        arr: energy,
    },
    {
        productimg: product14,
        heading: "ENVIRONMENTAL SENSOR",
        para: "The ES900 is a system ensures accurate and reliable emission data, enabling industries to maintain compliance with regulatory standards efficiently.",
        name: "ENVIRONMENTAL SENSOR",
        arr: environmental,
    },
    {
        productimg: product16,
        heading: "GSM DATA LOGGER",
        para: "The CELL900D is a sophisticated system that offers real-time data transmission, enabling remote monitoring and management of critical parameters.",
        name: "GSM DATA LOGGER",
        arr: gsm,
    },
    {
        productimg: product11,
        heading: "MILK TANK CONTROL & MONITORING SYSTEM",
        para: "CELL900M is an intelligent solution for Controlling and Monitoring the milk tank. It is very user-friendly, reliable, and very cost effective as compare with other renown brands.",
        name: "MILK TANK CONTROL & MONITORING SYSTEM",
        arr: milk,
    },
    {
        productimg: product12,
        heading: "EMISSION MONITORING SYSTEM",
        para: "The EMS3600 is a system ensures accurate and reliable emission data, enabling industries to maintain compliance with regulatory standards efficiently.",
        name: "EMISSION MONITORING SYSTEM",
        arr: emission,
    },
    {
        productimg: product15,
        heading: "GENSET HEALTH MONITORING SYSTEM",
        para: "The CELL900G is a cutting-edge solution for monitoring and maintaining generator sets, providing real-time insights into their operational health and performance across various industrial and commercial applications.",
        name: "GENSET HEALTH MONITORING SYSTEM",
        arr: genset,
    },
    {
        productimg: product13,
        heading: "RESISTOR DEPENDING TEMPERATURE SENSOR",
        para: "RDT900 offers precise temperature and monitoring with advanced sensor technology as compare with other well-known brands.",
        name: "RESISTOR DEPENDING TEMPERATURE SENSOR",
        arr: resistor,
    },
    {
        productimg: product10,
        heading: "TEMPERATURE & HUMIDITY DATA TERMINAL",
        para: "OTH900 offers precise temperature and humidity monitoring with advanced sensor technology as compare with other well-known brands.",
        name: "TEMPERATURE & HUMIDITY DATA TERMINAL",
        arr: temphumidity,
    },
    {
        productimg: product9,
        heading: "IR TEMPERATURE SENSOR DATA TERMINAL",
        para: "The IRT900 ensures precise monitoring of temperature levels with advanced sensor capabilities, making it an ideal choice for reliable temperature management in various applications.",
        name: "IR TEMPERATURE SENSOR DATA TERMINAL",
        arr: tempsensor,
    },
]

export default function Product() {
    const navigate = useNavigate();
    return (
        <>
            <div className="container-latestproduct-main" >
            <div className='productbar'></div>
                <div className="heading-latestproduct-top">PRODUCTS</div>
                <div className="container-latestproduct">
                    {
                        productMap.map((product,i) => {
                            return (
                                <div key={i} className="latestproduct">
                                       <div class="img-wrapper" onClick={() => {
                  navigate("/products", {
                    state: {
                      name: product.name,
                      arrrr: product.arr,
                    },
                  });
                }}>
                  <img class="inner-img" src={product.productimg} alt="uploading Another Image zoom-on-hover effect" />
                </div>
                                    <div className="heading-latestproduct">{product.heading}</div>
                                    <div className="para-homeproduct">
                                        {product.para}
                                    </div>
                              
                                     <div 
                  onClick={() => {
                    navigate("/products", {
                      state: {
                        name: product.name,
                        arrrr: product.arr,

                      },
                    });
                  }}>
  
                                       <a className='btn-latestproduct'>Read More</a>                        
                                </div>
                                </div>
                                
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}
