import './App.css';

import React, { useState } from 'react';

// import HomeMain from './MainComponents/HomeMain.jsx';
// import ContactUsMain from './MainComponents/ContactUsMain.jsx';
// import BmsMain from './MainComponents/BmsMain.jsx';
// import AboutMain from './MainComponents/AboutMain.jsx';
// import ProductMain from './MainComponents/ProductMain.jsx';
// import ProjectMain from './MainComponents/ProjectMain.jsx';
// import ProjectCompanyMain from './MainComponents/Project1/ProjectCompanyMain.jsx';
// import CompanyProjectMain from './MainComponents/Project1/Project2/CompanyProjectMain.jsx';
// import ProductCompanyMain from './MainComponents/Product1/ProductCompanyMain.jsx';
// import CompanyProductMain from './MainComponents/Product1/Product2/CompanyProductMain.jsx';
// import CareerMain from './MainComponents/CareerMain.jsx';
// import PageNot from './MainComponents/PageNot.jsx';


import Navbar from './Component/Navbar/Navbar';
import HomeMain from './Component/Home/HomeMain';
import AboutMain from './Component/About/AboutMain';
import ProductComponent from './Component/Product/ProComponent';
import Bms from './Component/Bms/Bms'
import Contact from './Component/Contact/Contact'
import Career from './Component/Career/Career'
// import Main from './Component/ComponentMain'
import Error from './Component/PageNotFound/PageErrorMain'
import ProjectDetail from './Component/Project/ProjectProduct/ProjectDetail/_ProjectDetailMain'
import ProductDetail from './Component/Home/Product/ProductProduct/ProductDetail/_ProductDetailMain';
import Footer from './Component/Footer/Footer';

import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
} from "react-router-dom";
import ProjectMain from './Component/Project/ProjectMain';

function App() {
  const [setLoading] = useState(true);
  const spinner = document.getElementById("spinner");
  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    }, 2000);
  }
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/home" element={<HomeMain />} />
          <Route path="/" element={<Navigate replace to="/home" />} />
          <Route path="/about" element={<AboutMain />} />
          <Route path="/product" element={<ProductComponent />} />
          <Route path="/project" element={<ProjectMain />} />
          <Route path="/bms" element={<Bms />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/Projects" element={<ProjectDetail />} />
          <Route path="/products" element={<ProductDetail />} />
          {/* <Route exact path="/about" element={<AboutMain />}/> */}
          {/* <Route exact path="/product" element={<ProductMain />}/> */}
          {/* <Route exact path="/project" element={<ProjectMain />}/> */}
          {/* <Route exact path="/project/projectCompany" element={<ProjectCompanyMain />}/> */}
          {/* <Route exact path="/project/projectCompany/Projects" element={<CompanyProjectMain />}/> */}
          {/* <Route exact path="/product/productCompany" element={<ProductCompanyMain />}/> */}
          {/* <Route exact path="/product/productCompany/Products" element={<CompanyProductMain />}/> */}
          {/* <Route exact path="/bms" element={<BmsMain />}/> */}
          {/* <Route exact path="/contactus" element={<ContactUsMain />}/> */}
          {/* <Route exact path="/career" element={<CareerMain />}/> */}

          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
