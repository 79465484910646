import React from "react";

import './ProductDetail.css'

import {useLocation} from "react-router-dom";

export default function ProductDetail() {
  const location = useLocation();
  let name = location.state?.name;
  let arr = location.state?.arrrr;

  // const navigate = useNavigate();
  return (
    <>
      <div className="mainContainerAirway">
      <div className='productdetailbar'></div>
        <div className="headingAirway">{name}</div>
        <div className="MainCompanyContainerway">
          {arr.map((companylink,i) => {
            return (
              <div key={i}>
                <a className="imgcompanyway" target={"_blank"} rel="noreferrer" href={companylink.link}><img src={companylink.img} alt="Uploading"/></a>
                <a className="companynameway" target={"_blank"} rel="noreferrer" href={companylink.download} download={companylink.download}> {companylink.companyname}</a>
              </div>
            );
          })}
        </div>
      </div>

    </>
  );
}
