import React from 'react'

import AboutHistory from './AboutHistory/AboutHistory'
import AboutTeam from './AboutTeam/AboutTeam'

export default function AboutMain() {
  return (
    <>
      <div>
        <AboutHistory />
        <AboutTeam />
      </div>
    </>
  )
}