import React from 'react'

import { useNavigate } from "react-router-dom";

import './ProjectProduct.css'

import project1 from "../../../assets/projects/Fusion/3.jpg";
import project2 from "../../../assets/projects/Air Flow Meter/23.jpg";
import project3 from "../../../assets/projects/Gass Flow Meter/4.jpg";
import project4 from "../../../assets/projects/Steam Flow Meter/21.jpg";
import project5 from "../../../assets/projects/Oxygen Analyser/3.jpg";
import project6 from "../../../assets/projects/Condensate Metering Pump/1_1.jpg";
import { fusioncompany, aircompany, gascompany, steamcompany, emissioncompany, condensatecompany } from './ProjectDetail/ProjectsArray';

import customerlist from '../../../assets/pdf/Hunch Automation Customers & Projects.pdf'

const projectMap = [
  {
    projectimg: project1,
    projecthead: "FUSION SYSTEM",
    projectdetail:
      "FUSION is a pre-engineered Automation System for gas/oil fired industrial steam boilers water tube/smoke tube, gas/oil fired chillers, furnaces, duct burners.",
    name: "FUSION SYSTEM",
    arr: fusioncompany,
  },
  {
    projectimg: project2,
    projecthead: "AIR FLOW METER",
    projectdetail:
      "OGF5400 latest version is an intelligent solution for accurate consumption measurement for compressed air and gases.",
    name: "AIR FLOW METER",
    arr: aircompany,
  },
  {
    projectimg: project3,
    projecthead: "GAS FLOW METER",
    projectdetail:
      "OGF5400 latest version is an intelligent solution for accurate consumption measurement for compressed air and gases.",
    name: "GAS FLOW METER",
    arr: gascompany,
  },
  {
    projectimg: project4,
    projecthead: "STEAM FLOW METER",
    projectdetail:
      "FCU5400 is a Differential pressure (DP) based flow measurements with temperature and pressure compensation.",
    name: "STEAM FLOW METER",
    arr: steamcompany,
  },
  {
    projectimg: project5,
    projecthead: "EMISSION MONITORING SYSTEM",
    projectdetail:
      "The OXY3600 oxygen probe with zirconia sensor and process display unit are the most advanced process system as compare other similar systems available.",
    name: "EMISSION MONITORING SYSTEM",
    arr: emissioncompany,
  },
  {
    projectimg: project6,
    projecthead: "CONDENSATE METERING PUMP",
    projectdetail:
      "OCMP9000 are accurate, reliable, and very costeffective as compare with other renobrands. Latestmetering pumps are available from 30 Liteto largervolume up to 120 Liters.",
    name: "CONDENSATE METERING PUMP",
    arr: condensatecompany,
  },
]

export default function ProjectProduct() {
  const navigate = useNavigate();

  return (
    <>
      <div className="mainProjectContainer">
      <div className='projectbar'></div>
        <div className="customerheadingbtn">
          <div className="headingProject">PROJECTS</div>
          <a className='customerlistlink' rel='noreferrer' target={"_blank"} download={customerlist} href={customerlist}>Download Customer List</a>
        </div>
        <div className="project">
          {projectMap.map((project,i) => {
            return (
              <div key={i} className="projectContainer">
                <div className="projectimglink" onClick={() => {
                  navigate("/projects", {
                    state: {
                      name: project.name,
                      arrrr: project.arr,
                    },
                  });
                }}>
                  <img className='projectproductimg' src={project.projectimg} alt="uploading" />
                </div>
                <div
                  className="projectheadinglink"
                  onClick={() => {
                    navigate("/projects", {
                      state: {
                        name: project.name,
                        arrrr: project.arr,
                      },
                    });
                  }}>
                  {project.projecthead}
                </div>
                <div
                  className="projectbtnlink"
                  onClick={() => {
                    navigate("/projects", {
                      state: {
                        name: project.name,
                        arrrr: project.arr,
                      },
                    });
                  }}>
                  More Projects
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
