import React from 'react'
import Project1 from '../../assets/background/Project1.mp4'
import ProjectProduct from './ProjectProduct/ProjectProduct'
import './ProjectMain.css'


// import ProjectCustomer from './ProjectCustomer/Customer'


export default function ProjectMain() {
  return (
    <>
    <div className='main'>
    <video src={Project1} autoPlay muted loop resizeMode='cover' width="100%" height="780" controls/>
   </div>
      <div>
        <ProjectProduct/>
        {/* <ProjectCustomer /> */}
      </div>
    </>
  )
}