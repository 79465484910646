import React from 'react'

// import ProjectHead from './ProjectHead';

import ProductDetail from './ProductDetail';

export default function ProductDetailMain() {
  return (
    <>
      {/* <ProjectHead /> */}
      <ProductDetail />
    </>
  )
}
