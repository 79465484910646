import React from 'react'

import PageError from './PageError'

export default function PageErrorMain() {
  return (
    <>
      <PageError />
    </>
  )
}
