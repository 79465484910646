import React from "react";

import './ProjectDetail.css'


import {useLocation} from "react-router-dom";

export default function ProjectDetail() {
  const location = useLocation();
  let name = location.state?.name;
  let arr = location.state?.arrrr;

  // const navigate = useNavigate();
  return (
    <>
      <div className="mainContainerAir">
      <div className='projectdetailbar'></div>
        <div className="headingAir">{name} PROJECTS</div>
        <div className="MainCompanyContainer">
          {arr.map((companylink,i) => {
            return (
              <div key={i} className="mainContainerProjectCompany">
                <a className="imgcompany" target={"_blank"} rel="noreferrer" href={companylink.link}><img src={companylink.img} alt="Uploading"/></a>
                <a className="companyname" target={"_blank"} rel="noreferrer" href={companylink.link}> {companylink.companyname}</a>
              </div>
            );
          })}
        </div>
      </div>

    </>
  );
}
