import React from 'react'
import './CommonImage.css'

export default function CommonImage(props) {
  return (
    <>
    {/* <div className='CommonImageMainContainer'>
    </div> */}
    <div className="commonImage">
        <img src={props.img} alt="" />
    </div>
    </>

  )
}