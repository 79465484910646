import React from 'react'

import './Footer.css'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { WhatsApp } from '@mui/icons-material';
import { Link } from 'react-scroll';

import logo1 from '../../assets/logo/logo1.png';

export default function Footer() {
    return (
        <>
        
            <div className="footMain">
                <div className="mainfootContainer">
                    <div className="linkContainer">
            <div className='follow-class'>FOLLOW US</div>
                        <div id="social">
                            <a id='facebook' target={"_blank"} rel="noreferrer" href="https://www.facebook.com/automation.hunch"><FacebookIcon className="Links face" /></a>
                            <a id='twitter' target={"_blank"} rel="noreferrer" href="https://twitter.com/HunchAutomation"><TwitterIcon className='Links twit' /></a>
                            <a id='linkedin' target={"_blank"} rel="noreferrer" href="https://www.linkedin.com/company/hunch-automation/mycompany/"><LinkedInIcon className='Links linkin' /></a>
                            <a id='instagram' target={"_blank"} rel="noreferrer" href="https://www.instagram.com/hunchautomation/"><InstagramIcon className='Links insta' /></a>
                            <a id='youtube' target={"_blank"} rel="noreferrer" href="https://www.youtube.com/channel/UC4e-X0cdFQhA5GuuqhqRwqA/featured"><YouTubeIcon className='Links you' /></a>
                        </div>
                    </div>
                    <div className="footpart2">
                        <img src={logo1} alt="Uploded" />
                        <div className="contactfoot">    
                            <div className='heading-class'><Link className='heading-class' to="contactus">CONTACT US</Link></div>
                            
                        
                            <div id="location" className='contactdetails'>
                                <span id="icon-location" className='icon'><LocationOnIcon /></span>
                                <div id="detail-location" className='details'> <span className='boldd'> HUNCH AUTOMATION PRIVATE LIMITED</span> <br />
                                    4/17 M Block, Gulberg III Lahore, Pakistan</div>
                            </div>
                            <div className="phoneemail">
                                <div id="phone" className='contactdetails'>
                                    <div id="icon-phone" className='icon'><LocalPhoneIcon /></div>
                                    <div id="detail-phone" className='details'><a className='phonelink footerlink' rel="noreferrer" target={"_blank"} href="https://wa.me/+923454071221">+92 345 407 122 1</a> </div>
                                </div>
                                <div id="email" className='contactdetails'>
                                    <div id="icon-email" className='icon'><MailIcon /></div>
                                    <div id="detail-email" className='details'><a className='maillink footerlink' href="mailto:info@hunch.com.pk" target="_blank" rel="noopener noreferrer"> info@hunch.com.pk </a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="copy-rights">
                    <p>© 2006 - 2024 HUNCH Automation Private Limited. All rights reserved.</p>
                </div>
                <a target={"_blank"} rel = "noreferrer" href="https://wa.me/+923454071221" className='btn-arrowlink'><WhatsApp id='btn-arrow' /></a>
                {/* <Link to="home" className='btn-arrowlink'><ArrowUpwardIcon id='btn-arrow' /></Link> */}
            </div>
        </>
    )
}
