import React from "react";

import './Customer.css'

import Customer1 from  '../../../assets/home/customer 1.jpg';
import Customer2 from  '../../../assets/home/customer 2.jpg';
import Customer3 from  '../../../assets/home/customer 3.jpg';
import Customer4 from  '../../../assets/home/customer 4.jpg';
import Customer5 from  '../../../assets/home/customer 5.jpg';
import Customer6 from  '../../../assets/home/customer 6.jpg';
import Customer7 from  '../../../assets/home/customer 7.jpg';
import Customer8 from  '../../../assets/home/customer 8.jpg';
import Customer9 from  '../../../assets/home/customer 9.jpg';
import Customer10 from '../../../assets/home/customer 10.jpg';
import Customer11 from '../../../assets/home/customer 11.jpg';
import Customer12 from '../../../assets/home/customer 12.jpg';
import Customer13 from '../../../assets/home/customer 13.jpg';
import Customer14 from '../../../assets/home/customer 14.jpg';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const customerMap = [
  {
    customerimg: Customer1,
  },
  {
    customerimg: Customer2,
  },
  {
    customerimg: Customer3,
  },
  {
    customerimg: Customer4,
  },
  {
    customerimg: Customer5,
  },
  {
    customerimg: Customer6,
  },
  {
    customerimg: Customer7,
  },
  {
    customerimg: Customer8,
  },
  {
    customerimg: Customer9,
  },
  {
    customerimg: Customer10,
  },
  {
    customerimg: Customer11,
  },
  {
    customerimg: Customer12,
  },
  {
    customerimg: Customer13,
  },
  {
    customerimg: Customer14,
  },
]

export default function Customer() {
  return (
    <>
        <div className="slider-main-container">
          <div className="customerbar"></div>
        <div className="heading-slide-customers">CUSTOMERS</div>
        <Swiper id="swiper-customer"
          slidesPerView={6}
          spaceBetween={80}
          slidesPerGroup={2}
          loop={true}
          loopFillGroupWithBlank={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            200: {
              slidesPerView: 2,
              spaceBetween: 80,
              slidesPerGroup: 1,
            },
            600: {
              slidesPerView: 4,
              spaceBetween: 80,
              slidesPerGroup: 2,
            },
            800: {
              slidesPerView: 6,
              spaceBetween: 80,
              slidesPerGroup: 2,
            },
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >{
            customerMap.map((Customer,i) => {
              return (

                <SwiperSlide key={i} className="swiperslide-customer"><img src={Customer.customerimg} alt="Uploaded" /></SwiperSlide>
              )
            })
          }
        </Swiper>
      </div>
    </>
  );
} 