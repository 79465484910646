import React from 'react'

// import Navbar from '../Navbar/Navbar'
import SliderProduct from './ProductSlider/SliderProduct';
import Product from './Product/ProductProduct/Product';
import ProjectCustomer from './ProjectCustomer/Customer'

export default function HomeMain() {
  return (
    <>
      {/* <Navbar /> */}
      <SliderProduct />
      <Product/>
      <ProjectCustomer/>
    </>
  )
}
 