import React from 'react'
import './AboutHistory.css'

import companyprofile from '../../../assets/pdf/Hunch Automation Company Profile.pdf'

export default function AboutHistory() {
  return (
    <>
      <div className="container-abouthistory">
      <div className='aboutbar'></div>
        <div className="headingbtn">
          <div className="heading-abouthistory">Brief Introduction</div>
          <a rel='noreferrer' className='companyprofilelink' target={"_blank"} href={companyprofile} download={companyprofile} >Download Company Profile</a>
        </div>
        <div className="para-abouthistory">
          HUNCH Automation Private Limited is an innovative Controls Engineering firm that specializes in the optimization of industrial operations. For over 18 years, we have spearheaded the progressive use of existing and emerging technologies in process control, systems integration, networking, consulting, design, and maintenance. One of the many things that sets us apart from our competitors is our belief that optimization does not have to mean replacement. We pride ourselves on finding the best control strategies and optimization options for increasing our client’s productivity and profitability. Our goal at HUNCH Automation is simple: To provide our client regardless of their industry with the right tools to competitively manufacture the best products in the most cost-effective way. Our engineered industrial products are unique solutions to the contemporary productivity and maintenance issues that plague many industries. At HUNCH Automation, we revel in the challenge of meeting and exceeding your engineering needs. As a leader in the design and implementation of industrial process control systems, our solutions address your short and long-term requirements, enabling you to gain and hold the competitive advantage well into the future. At, our progressive thinking, ability to foresee technology trends, and extensive knowledge of existing technologies, ensures that our clients find the optimum solution for their requirements. We assist our clients in making the transition from old to new technology without replacing their entire systems, and we inform our clients about the future implications of vendor offerings, enabling them to make choices that will facilitate present and future expansions and upgrades. We implement solutions at all functional levels on time and within budget.
        </div>
        {/* <div className="headstory">VISION STATEMENT</div> */}
        <div className="para-abouthistory">
          <strong className="headstory">VISION STATEMENT :</strong>
          To provide our customers best services, products, automation solutions and energy conservation consultancy.
        </div>
        {/* <div className="headstory">MISSION STATEMENT</div> */}
        <div className="para-abouthistory">
          <strong className="headstory">MISSION STATEMENT :</strong>
          To provide the highest quality product possible through our highly skilled and dedicated employees and state-of-the-art and patented products and manufacturing process. To pursue an aggressive, outgoing research and development program thus insuring its future as a leader in Industrial automation and energy conservation products. To provide the highest standards of service available through our highly trained, domestic and international field representatives and customer service personnel. Proper employees are expected to serve the customer with the highest level of technical knowledge and skills in the industry.
        </div>
        {/* <div className="headstory">PASSION</div> */}
        <div className="para-abouthistory">
          <strong className="headstory">PASSION :</strong>
          <strong>Made In Pakistan: </strong>
          Strive for development of local engineering solutions and products for industrial and commercial sectors to becoming pride of Pakistan. Creating engineering jobs for our engineers to reduce brain drain for foreign countries jobs. Making Pakistan green and prosper by providing cost effective, energy saving and reliable industrial solutions & products to reduce import bill and dollar drain. </div>
        {/* <div className="headstory">2006    REGISTRATION</div> */}
        <div className="para-abouthistory">
          <strong className="headstory">2006    REGISTRATION :</strong>
          Start own company HUNCH Automation Private Limited by registering it in May 2006. Functioning as a local distributor and system integrators of renowned automation products brands. Completed lot of automation projects.
        </div>
        {/* <div className="headstory">2007    FUSION</div> */}
        <div className="para-abouthistory">
          <strong className="headstory">2007 FUSION :</strong>
          Fusion system (Micro Modulation Combustion Control with O2 Trim) for fired boilers and oil heaters developed by our local engineering team 1st time in Pakistan.
        </div>
        {/* <div className="headstory">2008    GSM9000</div> */}
        <div className="para-abouthistory">
          <strong className="headstory">2008    GSM9000 :</strong>
          Battery powered GSM based data logger deployed at water flow meters for Karachi Water Board with online monitoring & reporting software. Help to reduce import bill in millions by our products.
        </div>
        {/* <div className="headstory">2010 OGF5400</div> */}
        <div className="para-abouthistory">
          <strong className="headstory">2010 OGF5400 :</strong>
          Our engineers have developed 1st embedded Air/Gas flow meter.
        </div>
        {/* <div className="headstory">2011    FCU5400</div> */}
        <div className="para-abouthistory">
          <strong className="headstory">2011 FCU5400 :</strong>
          Our engineers have developed 1st embedded steam flow meter.
        </div>
        {/* <div className="headstory">2013    GLOBAL BUSINESS</div> */}
        <div className="para-abouthistory">
          <strong className="headstory">2013 GLOBAL BUSINESS :</strong>
          Business opportunities in Bahrain & Saudi Arabia collaboration with our partner company National Industrial.
        </div>
        {/* <div className="headstory">2014    SOFTWARE DEVELOPMENT</div> */}
        <div className="para-abouthistory">
          <strong className="headstory">2014 SOFTWARE DEVELOPMENT :</strong>
          Enhancement of monitoring & reporting software development, replacing renown brands SCADA in industrial applications. Cost effective, compact, and reliable solution.
        </div>
        {/* <div className="headstory">2016    EMBEDDED DEVELOPMENT</div> */}
        <div className="para-abouthistory">
          <strong className="headstory">2016 EMBEDDED DEVELOPMENT :</strong>
          Enhancement of flow meters embedded design, gas/air, and steam by implementing dual core CPU board with WiFi browser enable, platform free. State of art IoT solution.
        </div>
        {/* <div className="headstory">2018    BOILER ACCESSORIES</div> */}
        <div className="para-abouthistory">
          <strong className="headstory">2018 BOILER ACCESSORIES :</strong>
          Development of highly efficient chamber design economizers for steam boilers, auto blow down with TDS sensor.
        </div>
        {/* <div className="headstory">2019    CMP36000</div> */}
        <div className="para-abouthistory">
          <strong className="headstory">2019 CMP36000 :</strong>
          Development of condensate meter pump for condensate transfer and measurement.
        </div>
        {/* <div className="headstory">2020 EXPORTING</div> */}
        <div className="para-abouthistory">
          <strong className="headstory">2020 EXPORTING :</strong>
          Fusion system and flow meters exporting to Bahrain.
        </div>
        {/* <div className="headstory">2021    DEVELOPMENTS</div> */}
        <div className="para-abouthistory">
          <strong className="headstory">2021 DEVELOPMENTS :</strong>
          Exporting 2nd order to Bahrain, enhancement of online monitoring & reporting system MRS9000. Development of ultrasonic water flow meters. Development of compact O2 system for boilers.
        </div>
         {/* <div className="headstory">2023    Blank</div> */}
         <div className="para-abouthistory">
          <strong className="headstory">2023 :</strong>
          Soon Update
        </div>
      </div>
    </>
  )
}
