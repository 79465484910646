import React from "react"

import './SliderProduct'

import './SliderProduct.css'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import "swiper/css/navigation";

// import required modules
import { EffectFade, Autoplay, Pagination, Navigation } from "swiper";
// import { Link } from "react-scroll";

import slide1 from '../../../assets/background/Slide1.jpg'
// import slide1 from '../../../assets/background/Slide1.jpg'
// import project1 from '../../../assets/background/11.jpg';
import project2 from '../../../assets/background/Slide2.jpg';
import project3 from '../../../assets/background/Slide3.jpg';
import project4 from '../../../assets/background/Slide4.jpg';
import project5 from '../../../assets/background/Slide5.jpg';

const homeSliderMap = [
  {
    slideimg: slide1,
    // slideheading: "FUSION SYSTEM",
    // slidepara: "Micro Modulation combustion control with O2 Trim & BMS. Control emission & cibserve, environment, operation cost, maintenance cost. Fusion micro modulation combustion control. ",
  },
  {
    slideimg: project2,
    // slideheading: "FUSION SYSTEM",
    // slidepara: "FUSION is a pre-engineered Automation System for gas/oil fired industrial steam boilers water tube/smoke tube, gas/oil fired chillers, furnaces and duct burners.",
  },
  {
    slideimg: project3,
  },
  {
    slideimg: project4,
     },
  {
    slideimg: project5,
  },
]

export default function SliderProduct() {
  return (
    <>
      <Swiper
        id="swiper-products"
        spaceBetween={30}
        effect={"fade"}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 9000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[EffectFade, Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {
          homeSliderMap.map((sliderMap,i) => {
            return (
              <SwiperSlide key={i} id="slide1" className="swiperslide-products"><div className="container">
                <img src={sliderMap.slideimg} alt="Uploading" />
                <div  className="details">
                  <div className="headingPara">
                    <div className="heading-slide">{sliderMap.slideheading}</div>
                    <div className="para-slide">{sliderMap.slidepara}</div>
                  </div>
                </div>
              </div>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </>
  );
}